import React from "react";
import styles from "./styles/home.module.css";
// @ts-ignore
import {ReactComponent as Check} from '../../shared/check.svg';


export const Info = () => {
    return (
        <div className={styles.cardsBlock}>
                <div className={styles.card}>
                    <Check />
                    <div>
                        <h2>Загрузите свою книгу</h2>
                        <div>форматы: FB2 и EPUB</div>
                    </div>
                </div>
                <div className={styles.card}>
                    <Check />
                    <div>
                        <h2>Читайте или слушайте</h2>
                        <div>Попробуйте озвучку бесплатно, чтобы выбрать между чтением и аудиоформатом</div>
                    </div>
                </div>
                <div className={styles.card}>
                    <Check />
                    <div>
                        <h2>Получайте уведомления</h2>
                        <div>Вырабатывайте привычку чтения, просто проверяя сообщения</div>
                    </div>
                </div>
        </div>
    )
}
