import {useEffect} from "react";

const useTheme = (theme: string) => {

    const currentTheme = theme.toLowerCase()

    useEffect(() => {
        const background = `var(--background-theme-${currentTheme})`
        const backgroundContainers = `var(--background-containers-theme-${currentTheme})`
        const backgroundBlock = `var(--background-block-theme-${currentTheme})`
        const fontColor = `var(--font-color-theme-${currentTheme})`

        document.body.style.setProperty('--background', background)
        document.body.style.setProperty('--background-containers', backgroundContainers)
        document.body.style.setProperty('--background-block', backgroundBlock)
        document.body.style.setProperty('--font-color', fontColor)

    }, [theme])
}

export default useTheme
