import React, {FC, useState} from "react";
import styles from "./styles/admin.module.css";
import {IChapter} from "../../types";
import {ModalNote} from "./ModalNote";
import {applyStyle} from "../../utils/applyStyle";

type ChapterPropsType = {
    chapter: IChapter
}

export const Chapter:FC<ChapterPropsType> = (props) => {
    const [noteText, setNoteText] = useState("")
    const [isOpenModal, setIsOpenModal] = useState(false)

    const showNote = (text: string) => {
        setNoteText(text)
        setIsOpenModal(true)
    }
    const closeModal = () => {
        setNoteText("")
        setIsOpenModal(false)
    }

    const styleText = (style?: string, note?: string) => {
        if(style) {
            if (style === "BOLD") {
                return {fontWeight: "bold"}
            } else if (style === "ITALIC") {
                return {fontStyle: "italic"}
            }
        }
        if(note){
            return {color: "#1f6a96", cursor: "pointer"}
        }
    }

    return (
        <div>
            {props.chapter.blocks.map(el =>
                <div key={el.id} className={styles.passage}>
                    {el.passages &&
                        <>
                            {el.passages.map((pas, i) =>
                                <span key={i}
                                      style={styleText(pas.style, pas.note)}
                                      onClick={pas.note ? () => showNote(pas.note) : () => {
                                      }}
                                >
                                    {applyStyle(pas.text, pas.style)}
                                </span>)
                            }
                        </>
                    }
                    {el.imageUrl &&
                        <img src={el.imageUrl}/>
                    }
                </div>
            )}
            {props.chapter.chapters &&
                <>
                    {props.chapter.chapters.map(el => <Chapter chapter={el} key={el.id}/>)}
                </>
            }
            {isOpenModal && <ModalNote text={noteText} closeModal={closeModal}/>}
        </div>
    )
}
