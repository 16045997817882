import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useLocation} from "react-router-dom";
import {noteAsync} from "../../app/reducers/bookFeedSlice";


export const Note = () => {
    const dispatch = useAppDispatch()
    const {
        note
    } = useAppSelector((state) => state.bookFeedReducer);

    const currentUrl = useLocation().pathname

    const params = currentUrl.split("book/")
    const bookId = +params[1].split("/")[0]
    const identifier = params[1].split("/")[2]

    useEffect(() => {
        dispatch(noteAsync(bookId, identifier))
    }, [])
    return (
        <div style={{margin: "20px",
            fontSize: "20px",
            lineHeight: "1.8"}}>
            {note}
        </div>
    )
}
