import {Book} from "../../types";

export const books: Book[] = [
    {
        id: 1,
        nameBook: 'Герой нашего времени',
        posts: [
            {
                text: "Часть первая.\nI. Бэла \n\nЯ ехал на перекладных из Тифлиса. Вся поклажа моей тележки состояла из одного небольшого чемодана, который до половины был набит путевыми записками о Грузии. Большая часть из них, к счастию для вас, потеряна, а чемодан с остальными вещами, к счастью для меня, остался цел.",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/lermontov_1.mp3",
                time: "0:18"
            },
            {
                text: "Уж солнце начинало прятаться за снеговой хребет, когда я въехал в Койшаурскую долину. Осетин-извозчик неутомимо погонял лошадей, чтоб успеть до ночи взобраться на Койшаурскую гору, и во все горло распевал песни. Славное место эта долина!",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/lermontov_2.mp3",
                time: "0:15"
            },
            {
                text: "Со всех сторон горы неприступные, красноватые скалы, обвешанные зеленым плющом и увенчанные купами чинар, желтые обрывы, исчерченные промоинами, а там высоко-высоко золотая бахрома снегов, а внизу Арагва, обнявшись с другой безыменной речкой, шумно вырывающейся из черного, полного мглою ущелья, тянется серебряною нитью и сверкает, как змея своею чешуею.",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/lermontov_3.mp3",
                time: "0:25"
            },

        ]
    },
    {
        id: 2,
        nameBook: 'Alice\'s Adventures in Wonderland',
        posts: [
            {
                text: "Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: once or twice she had peeped into the book her sister was reading, but it had no pictures or conversations in it, “and what is the use of a book,” thought Alice “without pictures or conversations?”",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/alice_1.mp3",
                time: "0:18"
            },
            {
                text: "So she was considering in her own mind (as well as she could, for the hot day made her feel very sleepy and stupid), whether the pleasure of making a daisy-chain would be worth the trouble of getting up and picking the daisies, when suddenly a White Rabbit with pink eyes ran close by her.",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/alice_2.mp3",
                time: "0:18"
            },
            {
                text: "There was nothing so remarkable in that; nor did Alice think it so much out of the way to hear the Rabbit say to itself, “Oh dear! Oh dear! I shall be late!”",
                audio: "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/alice_3.mp3",
                time: "0:09"
            }
        ]
    }
]

