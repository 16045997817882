import React, {useEffect, useState} from "react";
import styles from "./styles/admin.module.css";
import {useLocation, useNavigate} from "react-router-dom";

export const Navbar = () => {
    const navigate = useNavigate()

    const currentUrl = useLocation().pathname
    const currentPage = currentUrl.split("/admin")[1]
    const [currentPath, setCurrentPath] = useState<null | string>(null)
    useEffect(() => {
        setCurrentPath(currentPage)
    }, [])

    const navigateTo = (path: string) => {
        navigate(path)
        setCurrentPath(path)
    }
    return (
        <div>
            <div className={styles.navbar}>
                <div className={styles.tab}
                     onClick={() => navigateTo("/admin/books")}
                     style={currentPath === "books" ? {borderBottom: "6px solid #1f6a96"} : {}}
                >
                    книги
                </div>
                <div className={styles.tab}
                     onClick={() => navigateTo("/admin/files")}
                     style={currentPath === "files" ? {borderBottom: "6px solid #1f6a96"} : {}}
                >
                    файлы
                </div>
                <div className={styles.tab}
                     onClick={() => navigateTo("/admin/statistic")}
                     style={currentPath === "statistic" ? {borderBottom: "6px solid #1f6a96"} : {}}
                >
                    статистика
                </div>
            </div>
        </div>
    )
}
