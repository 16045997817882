import React, {FC, useState} from "react"
import {IPost} from "../../types";
import styles from "./styles/app.module.css";
import {PopupNote} from "./PopupNote";
import {applyStyle} from "../../utils/applyStyle";

type PostPropsType = {
    post: IPost
}

export const Post:FC<PostPropsType> = (props) => {

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [noteText, setNoteText] = useState("")

    const readNote = (noteNumber: number, note: string) => {
        setNoteText(`${noteNumber}. ${note}`)
        setIsShowPopup(true)
    }

    const closePopup = () => {
        setNoteText("")
        setIsShowPopup(false)
    }

    const styleText = (style?: string, note?: string) => {
        if(style) {
            if (style === "BOLD") {
                return {fontWeight: "bold"}
            } else if (style === "ITALIC") {
                return {fontStyle: "italic"}
            }
        }
        if(note){
            return {color: "#1f6a96", cursor: "pointer"}
        }
    }



    return (
        <div>
            <div className={styles.textContainer}>
                {props.post.blocks.map((curPar, i) =>
                    <div key={i}>
                        {curPar.imageUrl && <img src={curPar.imageUrl} alt={"img"}/>}
                        <div className={styles.paragraph}>
                            {curPar.passages.map((el, i) =>
                                <span key={i}
                                      id={"dropDown"}
                                      style={styleText(el.style, el.note)}
                                      onClick={el.note ? () => readNote(el.noteNumber, el.note) : () => {}}
                                >
                                    {applyStyle(el.text, el.style)}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {isShowPopup && <PopupNote text={noteText} close={closePopup} isShowPopup={isShowPopup}/>}
        </div>

    )
}
