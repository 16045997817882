import React from "react";
import styles from './styles/home.module.css'
import {useNavigate} from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate()

    const goToUserAgreement = () => {
        navigate('user-agreement')
    }
    const goToSupport = () => {
        window.open("https://t.me/booxfeed_support", '_blank')
    }
    return (
        <div className={styles.footer}>
            <div onClick={goToUserAgreement}>
                пользовательское соглашение
            </div>
            <div onClick={goToSupport}>
                поддержка
            </div>
        </div>
    )
}
