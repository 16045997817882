import {BaseClient} from "./BaseClient";
import {
    IAccessToken,
    IBook,
    IBookContent,
    IBooks,
    IChapter,
    IFile,
    IFiles,
    IPost,
    ISettings,
    IStatus,
    IUser,
    IUserInProgress
} from "../types";


export class BookFeedClient extends BaseClient {

    static getHeader(token: string) {
        return {Authorization: `Bearer ${token}`}
    }

    // USER

    static async login (initData: string) {
        const params = {
            initData
        }
        return this.post<IAccessToken>(`user/login`, null, {params})
    }

    static async getUser (token: string) {
        const headers = this.getHeader(token)
        return this.get<IUser>(`user/me`, {headers})
    }

    static async getContinueReading (token: string) {
        const headers = this.getHeader(token)
        return this.get<Array<IPost>>(`user/continue-reading`, {headers})
    }
    static async nextPost (token: string) {
        const headers = this.getHeader(token)
        return this.get<IPost>(`user/next-post`, {headers})
    }
    static async readingPost (token: string) {
        const headers = this.getHeader(token)
        return this.get<Array<IPost>>(`user/reading`, {headers})
    }

    static async updateSetting (setting: ISettings, token: string) {
        const headers = this.getHeader(token)
        return this.patch<ISettings>(`user/setting`, setting, {headers})
    }

    static async readStatus (token: string) {
        const headers = this.getHeader(token)
        return this.get<IStatus>(`user/read-status`, {headers})
    }

    static async bookContents (token: string) {
        const headers = this.getHeader(token)
        return this.get<Array<IBookContent>>(`user/book/contents`, {headers})
    }
    static async chapterContent (token: string, id: number) {
        const headers = this.getHeader(token)
        return this.get<IChapter>(`user/chapter/${id}`, {headers})
    }
    static async moveForward (token: string) {
        const headers = this.getHeader(token)
        return this.post<Array<IPost>>(`user/move-forward`, null,{headers})
    }

    static async note (bookId: number, identifier: string) {
        return this.get<string>(`book/${bookId}/note/${identifier}`)
    }

    // ADMIN

    //LOGIN
    static async loginAdmin (hash: string) {
        const headers = {
            Authorization: hash
        }
        return this.post<IAccessToken>(`user/admin/login`, null, {headers})
    }

    //BOOKS
    static async getBooksList (search: string, token: string, currentPage: number) {
        const params = {
            search,
            page: currentPage,
            size: 10
        }
        const headers = this.getHeader(token)
        return this.get<IBooks>(`admin/book`, {params, headers})
    }
    static async deleteBook (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.delete(`admin/book/${id}`,{headers})
    }

    static async getBookContent (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.get<Array<IBookContent>>(`admin/book/${id}/chapters`, {headers})
    }

    static async getBookChapterContent (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.get<IChapter>(`admin/chapter/${id}`, {headers})
    }
    static async getBook (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.get<IBook>(`admin/book/${id}`, {headers})
    }
    static async publishBook (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.post(`admin/book/${id}/publish`, null, {headers})
    }
    static async deleteChapter (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.delete(`admin/chapter/${id}`,{headers})
    }
    static async updateAnnotationBook (id: number, token: string, annotation: string) {
        const headers = this.getHeader(token)
        const body = {
            annotation
        }
        return this.post<IBook>(`admin/book/${id}/annotation`, body, {headers})
    }
    static async updateCoverBook (id: number, token: string, file: FormData) {
        const headers = this.getHeader(token)

        return this.post<IBook>(`admin/book/${id}/cover`, file, {headers})
    }

    //FILES

    static async getFilesList (search: string, token: string, currentPage: number) {
        const params = {
            search,
            page: currentPage,
            size: 10
        }
        const headers = this.getHeader(token)
        return this.get<IFiles>(`admin/file`, {params, headers})
    }

    static async addFile (file: FormData, token: string) {
        const headers = this.getHeader(token)
        return this.post<IFile>(`admin/file/upload`, file, {headers})
    }

    static async processFile (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.post<IFile>(`admin/file/process/${id}`, null, {headers})
    }

    static async deleteFile (id: number, token: string) {
        const headers = this.getHeader(token)
        return this.delete(`admin/file/${id}`,{headers})
    }

    //STATISTIC
    static async userInProgress (token: string, page: number) {
        const headers = this.getHeader(token)
        const params = {
            page: page,
            limit: 10
        }
        return this.get<Array<IUserInProgress>>(`admin/user/in-progress`,{params, headers})
    }
}
