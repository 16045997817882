import {BookFeed} from "./BookFeed";
import React, {FC} from "react";
import {ISettings} from "../../types";
import styles from "./styles/app.module.css"
import useTheme from "../../utils/useTheme";
import {useThemeParams} from "@vkruglikov/react-telegram-web-app";

type UserComponentPropsType = {
    userSettings: ISettings
}

export const UserComponent:FC<UserComponentPropsType> = (props) => {

    const [colorScheme, themeParams] = useThemeParams();
    const telegramSettings = {
        background: themeParams.bg_color!,
        backgroundContainers: themeParams.secondary_bg_color!,
        backgroundBlock: themeParams.hint_color!,
        color: themeParams.text_color!
    }
    document.body.style.setProperty('--background-theme-telegram', telegramSettings.background)
    document.body.style.setProperty('--background-containers-theme-telegram', telegramSettings.backgroundContainers)
    document.body.style.setProperty('--background-block-theme-telegram', telegramSettings.backgroundBlock)
    document.body.style.setProperty('--font-color-theme-telegram', telegramSettings.color)

    useTheme(props.userSettings.theme)
    const styleApp = {
        fontSize: props.userSettings.fontSize,
        lineHeight: props.userSettings.lineHeight
    }

    return (
        <div style={styleApp} className={styles.userComponent}>
            <BookFeed/>
        </div>
    )
}

