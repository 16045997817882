import React, {FC, useEffect} from "react";
import styles from "../styles/app.module.css";
import Slider from "rc-slider";
import {ISettings} from "../../../types";
import {useAppDispatch} from "../../../app/hooks";
import {updateSettingsAsync} from "../../../app/reducers/userSlice";

type TextSettingComponentPropsType = {
    userSetting: ISettings,
}

export const TextSettingComponent:FC<TextSettingComponentPropsType> = (props) => {
    const dispatch = useAppDispatch()

    const changeFontSize = (value: number | number[]) => {
        console.log(value)
            const newSettings = {
                theme: props.userSetting.theme,
                fontSize: typeof value === "number" ? value : props.userSetting.fontSize,
                fontStyle: props.userSetting.fontStyle,
                lineHeight: props.userSetting.lineHeight
            }
            dispatch(updateSettingsAsync(newSettings))
    }
    const changeLineHeight = (value: number | number[]) => {
        const newSettings = {
            theme: props.userSetting.theme,
            lineHeight: typeof value === "number" ? value : props.userSetting.lineHeight,
            fontSize: props.userSetting.fontSize,
            fontStyle: props.userSetting.fontStyle
        }
        dispatch(updateSettingsAsync(newSettings))
    }
    useEffect(() => {
        console.log(`state: ${props.userSetting.fontSize}`)
    }, [props.userSetting.fontSize])
    return (
        <div className={styles.block}>
            <div className={styles.settingBlock}>
                Шрифт:
                <div style={{width: "90%", margin: "10px"}}>
                    <Slider step={2}
                            min={16}
                            max={40}
                            handleStyle={[{width: "20px", height: "20px", marginTop: "-7px"}]}
                            defaultValue={props.userSetting.fontSize}
                            onChange={(e) => changeFontSize(e)}
                    />
                </div>
            </div>
            <div className={styles.settingBlock}>
                Интервал:
                <div style={{width: "90%", margin: "10px"}}>
                    <Slider step={0.1}
                            min={1.2}
                            max={3}
                            defaultValue={props.userSetting.lineHeight}
                            handleStyle={[{width: "20px", height: "20px", marginTop: "-7px"}]}
                            onChange={(e) => changeLineHeight(e)}
                    />
                </div>
            </div>
        </div>
    )
}
