import React, {useState} from "react";
import styles from "./styles/home.module.css";
import {ReactComponent as Logo} from "../../shared/logo.svg";
import {AudioPlayer} from "./AudioPlayer";
import {books} from "./utils";
import {Post} from "../../types";

export const Phone = () => {
    const [isStarting, setIsStarting] = useState(false);
    const [currentBookId, setCurrentBookId] = useState<null | number>(null);
    const [posts, setPosts] = useState<Post[]>([]);
    const [currentPostIndex, setCurrentPostIndex] = useState(0);
    const currentBook = books.find(book => book.id === currentBookId) || null;

    const [playingId, setPlayingId] = useState<number | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const startBot = () => {
        setIsStarting(true);
    };

    const chooseBook = (id: number) => {
        setCurrentBookId(id);
        setPosts([books[id - 1].posts[0]]);
        setCurrentPostIndex(1);
    };

    const nextPost = () => {
        if (currentBook && currentPostIndex < currentBook.posts.length) {
            setPosts(prevPosts => [...prevPosts, currentBook.posts[currentPostIndex]]);
            setCurrentPostIndex(prevIndex => prevIndex + 1);
        }else {
            window.open("https://t.me/BooxFeedBot", '_blank')
        }
    };
    const handlePlayToggle = (id: number) => {
        if (playingId === id) {
            setIsPlaying(!isPlaying);
        } else {
            setPlayingId(id);
            setIsPlaying(true);
        }
    };
    return (
        <div className={styles.phoneBorder}>
            <div className={styles.phone}>
                {isStarting ?
                    <div className={styles.headerPhone}>
                        <div className={styles.logo}>
                            <Logo/>
                        </div>
                        BooxFeed
                    </div>
                    :
                    <h4>Как это работает?</h4>
                }
                <div className={styles.messagesBlock}>
                    {(currentBookId && currentBook) &&
                        <>
                            <div className={styles.dateMessage}>Today</div>
                            {posts.map((el, index) => (
                                <div key={index} className={styles.messageGroup}>
                                    <div className={styles.message}>
                                        <AudioPlayer
                                            url={el.audio}
                                            id={index + 1}
                                            time={el.time}
                                            currentPlayingId={playingId}
                                            isPlaying={playingId === index + 1 && isPlaying}
                                            onPlayToggle={handlePlayToggle}
                                        />
                                        {el.text.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>

                                </div>
                            ))
                            }
                            {currentPostIndex < currentBook.posts.length &&
                                <div className={styles.nextBtn} onClick={nextPost}>
                                    Следующий пост
                                </div>
                            }
                        </>
                    }

                    {!currentBook && !isStarting &&
                        <Logo className={styles.startLogo}/>
                    }
                    {isStarting && !currentBookId && (
                        <div className={styles.changeBook}>
                            <div className={styles.message}>Выберите книгу:</div>
                            <div className={styles.book} onClick={() => chooseBook(1)}>
                                🇷🇺 Герой нашего времени
                            </div>
                            <div className={styles.book} onClick={() => chooseBook(2)}>
                                🇬🇧 Alice's Adventures in Wonderland
                            </div>
                        </div>
                    )}
                </div>
                {!isStarting && (
                    <div className={styles.btn} onClick={startBot}>
                        Start
                    </div>
                )}
                {currentBook && !(currentPostIndex < currentBook.posts.length) &&
                    <div className={styles.btn} onClick={nextPost}>
                        читате вашу книгу в телеграм!
                    </div>
                }
            </div>
        </div>
    )
}
