import React, {FC, useState} from "react";
import styles from "../styles/app.module.css";
import {IoColorPaletteOutline} from "react-icons/io5";
import {BsCardText} from "react-icons/bs";
import {LuSettings2} from "react-icons/lu";
import {ThemeSettingComponent} from "./ThemeSettingComponent";
import {TextSettingComponent} from "./TextSettingComponent";
import {SystemSettingComponent} from "./SystemSettingComponent";
import {ISettings} from "../../../types";


type SettingsContainerPropsType = {
    isOpenSettings: boolean,
    settings: ISettings,
}

export const SettingsContainer:FC<SettingsContainerPropsType> = (props) => {

    const [currentSetting, setCurrentSetting] = useState("theme")
    const chooseCurrentSetting = (set: string) => {
        setCurrentSetting(set)
    }
    const settingContainerStyles = {
        fontSize: 20,
        lineHeight: 1.2,
        bottom: props.isOpenSettings ? "0px" : "-100%"
    }

    return(
        <div className={styles.settings} id={"dropDown"} style={settingContainerStyles}>
            <div className={styles.settingsTabs}>
                <IoColorPaletteOutline onClick={()=>chooseCurrentSetting("theme")}/>
                <BsCardText onClick={()=>chooseCurrentSetting("text")}/>
                <LuSettings2 onClick={()=>chooseCurrentSetting("system")}/>
            </div>
            {currentSetting === "theme" &&
                <ThemeSettingComponent
                    userSetting={props.settings}
                />
            }
            {currentSetting === "text" &&
                <TextSettingComponent userSetting={props.settings}/>
            }
            {currentSetting === "system" &&
                <SystemSettingComponent/>
            }

        </div>
    )
}
