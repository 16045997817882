import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "./styles/admin.module.css"
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getBookContentAsync, getBookInfoAsync, updateAnnotationAsync, updateCoverAsync} from "../../app/reducers/books";
import {IBookContent} from "../../types";
import {RiLoaderFill} from "react-icons/ri";
import {loginAdminAsync} from "../../app/reducers/userSlice";
import {IoTrashOutline} from "react-icons/io5";
import {TiPencil} from "react-icons/ti";
import {FaCheck} from "react-icons/fa6";
import {ModalDeleteChapter} from "./ModalDeleteChapter";

export const CurrentBook = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        bookContent,
        isFetching,
        currentBook
    } = useAppSelector((state) => state.booksReducer);

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [currentChapterId, setCurrentChapterId] = useState<null | number>(null)
    const [isUpdateAnnotation, setIsUpdateAnnotation] = useState(false)
    const [annotationText, setAnnotationText] = useState<null | string>(null)

    const openDeleteModal = (e: React.MouseEvent<SVGElement>, id: number) => {
        e.stopPropagation()
        setIsOpenDeleteModal(true)
        setCurrentChapterId(id)
    }
    const closeDeleteModal = () => {
        setIsOpenDeleteModal(false)
        setCurrentChapterId(null)
    }

    useEffect(() => {
        if(currentBook){
            setAnnotationText(currentBook.annotation)
        }
    }, [currentBook])

    const currentUrl = useLocation().pathname
    const id = currentUrl.split("book/")[1]

    useEffect(() => {
        dispatch(loginAdminAsync())
        dispatch(getBookContentAsync(+id))
        dispatch(getBookInfoAsync(+id))
    }, [])

    const openChapter = (id: number) => {
        navigate(`chapter/${id}`)
    }

    const getSubChapters = (chapters: Array<IBookContent>, level: number) => {
        if (!chapters) return <></>;
        let titleStyle: string;
        switch (level) {
            case 1:
                titleStyle = styles.bookTitle;
                break;
            case 2:
                titleStyle = styles.subTitle_1;
                break;
            case 3:
                titleStyle = styles.subTitle_2;
                break;
            default:
                titleStyle = styles.subTitle_3;
                break;
        }
        return chapters.map((el, i) =>
            <div key={el.id}>
                <div className={titleStyle} onClick={() => openChapter(el.id)} style={{position: "relative"}}>
                    {el.title ? el.title : "***"}
                    <IoTrashOutline className={styles.trash}
                                    onClick={(e) => openDeleteModal(e, el.id)}
                    />
                </div>
                {el.subChapters && getSubChapters(el.subChapters, level + 1)}
            </div>
        );
    }

    const changeAnnotationText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnnotationText(e.target.value)
    }
    const updateAnnotation = () => {
        if(annotationText){
            dispatch(updateAnnotationAsync(+id, annotationText))
        }
        setIsUpdateAnnotation(false)
    }
    const loadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            dispatch(updateCoverAsync(+id, formData));
        }
    }

    return (
        <div className={styles.currentBookContainer}>
            {isFetching
                ?
                <div className={styles.fetch}>
                    <RiLoaderFill className={styles.loader}/>
                </div>
                :
                <>
                    {currentBook &&
                        <>
                            <div className={styles.chapterTitle}>Информация о книге:</div>
                            <div className={styles.bookInfoBlock}>
                                <div className={styles.infoBookItem}>
                                    <div className={styles.titleIfo}>Название:</div>
                                    <div>{currentBook.title}</div>
                                </div>
                                <div className={styles.infoBookItem}>
                                    <div className={styles.titleIfo}>Авторы:</div>
                                    {currentBook.authors.map(el => <div key={el.id}>{el.firstName} {el.lastName}</div>)}
                                </div>
                                <div className={styles.infoBookItem}>
                                    <div className={styles.titleIfo}>Аннотация:</div>
                                    <div className={styles.iconUpdate}
                                         onClick={() => setIsUpdateAnnotation(!isUpdateAnnotation)}
                                    >
                                        <TiPencil/>
                                    </div>
                                    <div className={styles.iconUpdate} style={{left: "130px"}}
                                         onClick={updateAnnotation}
                                    >
                                        {(annotationText && isUpdateAnnotation) && <FaCheck/>}
                                    </div>

                                    {isUpdateAnnotation ?
                                        <textarea value={annotationText ? annotationText : ""}
                                                  minLength={2000}
                                                  rows={5}
                                                  onChange={changeAnnotationText}
                                        />
                                        :
                                        <div className={styles.annotation}>
                                            {currentBook.annotation}
                                        </div>
                                    }
                                </div>
                                <div className={styles.infoBookItem}>
                                    <div className={styles.titleIfo}>Обложка:</div>
                                    {currentBook.coverUrl &&
                                        <img src={currentBook.coverUrl} className={styles.cover}/>
                                    }
                                    <div className={styles.addImageContainer}>
                                        <input type={"file"}
                                               className={styles.addImageInput}
                                               placeholder={"image"}
                                               onChange={loadImage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className={styles.chapterTitle}>Содержание:</div>
                    {getSubChapters(bookContent, 1)}
                </>
            }
            {
                isOpenDeleteModal && currentChapterId &&
                <ModalDeleteChapter text={"Удалить главу?"} closeModal={closeDeleteModal} id={currentChapterId}/>
            }
        </div>
    )
}
