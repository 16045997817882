import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useExpand} from "@vkruglikov/react-telegram-web-app";
import styles from './styles/app.module.css'
import {nextPostAsync} from "../../app/reducers/bookFeedSlice";
import {Post} from "./Post";
import {SettingsBar} from "./settings/SettingsBar";
import {scrollToElement} from "../../utils/scrollToElement";
import {CongratulationText} from "./CongratulationText";


export const BookFeed = () => {
    const dispatch = useAppDispatch()
    const {
        posts,
        status,
    } = useAppSelector((state) => state.bookFeedReducer);

    const [isScrolled, setIsScrolled] = useState(false)
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false)
    const [isCongratulation, setIsCongratulation] = useState(false)
    const [isExpanded, expand] = useExpand();

    const nextPost = () => {
        dispatch(nextPostAsync())
        setIsScrolledToEnd(false)
    }

    const lastPostDivRef = useRef<HTMLDivElement>(null);
    const targetBtnRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if(posts.length > 0 && !isScrolled && isExpanded){
            scrollToElement(lastPostDivRef)
            setIsScrolled(true)
        }
    }, [posts, isExpanded])

    const scrollToEndPost = () => {
        if (targetBtnRef.current) {
            targetBtnRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
        }
    };

    useEffect(() => {
        if(posts.length > 0 && isScrolledToEnd){
            scrollToEndPost()
        }
    }, [posts])



    return (
        <div className={styles.bookFeedContainer}>
            <SettingsBar setIsScrolledToEnd={setIsScrolledToEnd}/>
            <div>
                {posts.length > 0 && posts.map((post, i) =>
                    <div ref={i === posts.length - 1 ? lastPostDivRef : null} key={post.sentPostId}>
                        <Post post={post} />
                    </div>
                )}
            </div>

            {isCongratulation &&
                <CongratulationText isCongratulation={isCongratulation}/>
            }
            {!isCongratulation &&
                <>
                    {status && status.completed ?
                        <div className={styles.buttonNext}
                             onClick={() => setIsCongratulation(true)}
                        >
                            Завершить
                        </div>
                        :
                        <div className={styles.buttonNext}
                             onClick={nextPost}
                             ref={targetBtnRef}
                        >
                            Следующий пост
                        </div>
                    }
                </>
            }
        </div>
    )
}
