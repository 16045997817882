import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useLocation} from "react-router-dom";
import {getBookChapterContentAsync} from "../../app/reducers/books";
import styles from "./styles/admin.module.css"
import {RiLoaderFill} from "react-icons/ri";
import {Chapter} from "./Chapter";
import {loginAdminAsync} from "../../app/reducers/userSlice";

export const BookContent = () => {
    const dispatch = useAppDispatch()
    const {
        currentChapter,
        isFetching
    } = useAppSelector((state) => state.booksReducer);
    const {
        adminToken
    } = useAppSelector((state) => state.userReducer);
    const currentUrl = useLocation().pathname
    const id = currentUrl.split("chapter/")[1]

    useEffect(() => {
        dispatch(loginAdminAsync())
        if(adminToken){
            dispatch(getBookChapterContentAsync(+id))
        }
    }, [adminToken])

    return (
        <>
            {isFetching ?
                <div className={styles.fetch}>
                    <RiLoaderFill className={styles.loader}/>
                </div>
                :
                <>
                    {currentChapter &&
                        <div className={styles.chapterContainer}>
                            <Chapter chapter={currentChapter}/>
                        </div>
                    }
                </>
            }
        </>
    )
}
