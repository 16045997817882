import React, {FC, useEffect, useRef} from "react";
import styles from "./styles/app.module.css";


type CongratulationTextPropsType = {
    isCongratulation: boolean
}
export const CongratulationText:FC<CongratulationTextPropsType> = (props) => {
    const targetCongratulationText = useRef<HTMLHeadingElement>(null);
    const scrollToCongratulationText = () => {
        if (targetCongratulationText.current) {
            targetCongratulationText.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };
    useEffect(() => {
        if(props.isCongratulation){
            scrollToCongratulationText()
        }
    }, [props.isCongratulation])
    return (
        <h1 className={styles.playful} ref={targetCongratulationText}>
            <span aria-hidden="true">К</span>
            <span aria-hidden="true">н</span>
            <span aria-hidden="true">и</span>
            <span aria-hidden="true">г</span>
            <span aria-hidden="true">а</span>
            <span aria-hidden="true"> </span>
            <span aria-hidden="true">п</span>
            <span aria-hidden="true">р</span>
            <span aria-hidden="true">о</span>
            <span aria-hidden="true">ч</span>
            <span aria-hidden="true">и</span>
            <span aria-hidden="true">т</span>
            <span aria-hidden="true">а</span>
            <span aria-hidden="true">н</span>
            <span aria-hidden="true">а</span>
            <span aria-hidden="true">!</span>
        </h1>
    )
}
