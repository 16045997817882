import React, {useEffect} from "react";
import styles from "./styles/admin.module.css";
import {RiLoaderFill} from "react-icons/ri";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {loginAdminAsync} from "../../app/reducers/userSlice";
import {Admin} from "./Admin";

export const AdminLogin = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        adminToken,
        isFetching
    } = useAppSelector((state) => state.userReducer);

    const currentUrl = useLocation().pathname
    const hash = currentUrl.split("admin/")[1]

    useEffect(() => {
        dispatch(loginAdminAsync(hash))
        if(adminToken){
            navigate('/admin', {replace: true})
        }
    }, [adminToken])

    return(
        <>
            {isFetching ?
                <div className={styles.fetch}>
                    <RiLoaderFill className={styles.loader}/>
                </div>
                :
                <Admin/>
            }
        </>
    )
}
