import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {ISettings, IUser} from "../../types";
import {BookFeedClient} from "../../api/BookFeedClient";

interface appState {
    user: IUser | null,
    token: string | null,
    adminToken: string | null,
    isFetching: boolean,
    errorMessage: string | null,
}

const initialState: appState = {
    user: null,
    token: null,
    adminToken: null,
    isFetching: false,
    errorMessage: null
}


export const userSlice = createSlice (
    {
        name: 'user',
        initialState,
        reducers: {
            setToken: (state, action: PayloadAction<string>) => {
                state.token = action.payload
            },
            setUser: (state, action: PayloadAction<IUser>) => {
                state.user = action.payload
            },
            updateSettings: (state, action: PayloadAction<ISettings>) => {
                if(state.user){
                    state.user.setting = action.payload
                }
            },
            setAdminToken: (state, action:PayloadAction<string>) => {
                state.adminToken = action.payload
                state.errorMessage = null
            },
            setIsFetching: (state, action:PayloadAction<boolean>) => {
                state.isFetching = action.payload
            },
            setErrorMessage: (state, action: PayloadAction<string>) => {
                state.errorMessage = action.payload
            }
        }
    }
)


export const {
    setToken,
    setUser,
    updateSettings,
    setAdminToken,
    setIsFetching,
    setErrorMessage,
} = userSlice.actions

export const loginAsync = (initData: string): AppThunk => async  (dispatch: any) => {
    try {
        let result = await BookFeedClient.login(initData)
        dispatch(setToken(result.accessToken))
    } catch (error) {

    }
}
export const getUserAsync = (token: string): AppThunk => async  (dispatch: any) => {
    try {
        let result = await BookFeedClient.getUser(token)
        dispatch(setUser(result))
    } catch (error){

    }
}
export const updateSettingsAsync = (settings: ISettings): AppThunk => async  (dispatch: any, getState) => {
    try {
        const authReducer = getState().userReducer
        const token = authReducer.token
        if(token){
            let result = await BookFeedClient.updateSetting(settings, token)
            dispatch(updateSettings(result))
        }
    } catch (error){

    }
}

export const loginAdminAsync = (hash?: string): AppThunk => async  (dispatch: any) => {
    dispatch(setIsFetching(true))
    let token = localStorage.getItem("token")
    if(token){
        dispatch(setAdminToken(token))
    }else{
        if(hash){
            let result = await BookFeedClient.loginAdmin(hash)
            dispatch(setAdminToken(result.accessToken))
            localStorage.setItem("token", result.accessToken)
        }else{
            dispatch(setErrorMessage("Что-то пошло не так"))
        }
    }
    dispatch(setIsFetching(false))
}


export default userSlice.reducer;
