import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {BookFeedClient} from "../../api/BookFeedClient";
import {IUserInProgress} from "../../types";

interface appState {
    statistic: Array<IUserInProgress>,
    hasMoreStatistic: boolean,
    currentPageStatistic: number
}

const initialState: appState = {
    statistic: [],
    hasMoreStatistic: false,
    currentPageStatistic: 0
}


export const statisticSlice = createSlice(
    {
        name: 'statistic',
        initialState,
        reducers: {
            setStatistic: (state, action:PayloadAction<Array<IUserInProgress>>) => {
                state.statistic = [...state.statistic, ...action.payload]
                state.currentPageStatistic = state.currentPageStatistic + 1
                state.hasMoreStatistic = action.payload.length === 10
            }
        }
    }
)


export const {
    setStatistic
} = statisticSlice.actions



export const userInProgressAsync = (): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const statisticReducer = getState().statisticReducer
    const token = authReducer.adminToken
    let result = await BookFeedClient.userInProgress(token!, statisticReducer.currentPageStatistic)
    dispatch(setStatistic(result))
}

export default statisticSlice.reducer;
