import styles from './styles/home.module.css'
import {Phone} from "./Phone";
import {FaTelegramPlane} from "react-icons/fa";
import {Info} from "./Info";
import {Footer} from "./Footer";


export const HomePage = () => {
    const goToTelegram = () => {
        window.open("https://t.me/BooxFeedBot", '_blank')
    }
    return (
        <div className={styles.homePage}>
            <div className={styles.container_1}>
                <div className={styles.infoBlock}>
                    <div className={styles.block}>
                        <div className={styles.header}>BooxFeed</div>
                        <div className={styles.description}>
                            Наш бот разбирает книги на удобные порции и отправляет их в ваш Telegram!
                            <br/><br/>Читайте по чуть-чуть каждый день!
                        </div>
                        <div className={styles.btnStart} onClick={goToTelegram}>
                            <FaTelegramPlane/>
                            Начать читать
                        </div>
                    </div>
                    <div className={styles.block}>
                        <Phone/>
                    </div>
                </div>
            </div>
            <div className={styles.container_2}>
                <Info/>
            </div>
            <Footer/>
        </div>

    );
};
