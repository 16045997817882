import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import styles from "./styles/admin.module.css"
import {deleteBookAsync, getBooksListAsync, publishBookAsync, resetBooks} from "../../app/reducers/books";
import {CiSearch} from "react-icons/ci";
import {IoTrashOutline} from "react-icons/io5";
import {RiLoaderFill} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";
import {loginAdminAsync} from "../../app/reducers/userSlice";
import {Navbar} from "./Navbar";


export const Books = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {
        booksList,
        hasMoreBooks,
        isFetching,
    } = useAppSelector((state) => state.booksReducer);
    const {
        adminToken
    } = useAppSelector((state) => state.userReducer);

    useEffect(() => {
        dispatch(loginAdminAsync())
        if(adminToken){
            dispatch(getBooksListAsync(""))
        }
        return () => {
            dispatch(resetBooks())
        }
    }, [adminToken])

    const [searchBook, setSearchBook] = useState("")

    const changeSearchBook = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSearchBook(e.target.value)
        if(e.target.value === ""){
            dispatch(getBooksListAsync(""))
        }
    }

    const search = () => {
        dispatch(getBooksListAsync(searchBook))
    }
    const pressHandler = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            search()
        }
    };

    const loadMore = () => {
        search()
    }
    const deleteBook = (id: number) => {
        dispatch(deleteBookAsync(id))
    }
    const publishBook = (id: number) => {
        dispatch(publishBookAsync(id))
    }
    const openBook = (id: number) => {
        navigate(`book/${id}`)
    }
    return (
        <div>
            <Navbar/>
            <div className={styles.search}>
                <input placeholder={"поиск"}
                       value={searchBook}
                       onChange={changeSearchBook}
                       onKeyPress={pressHandler}
                />
                <CiSearch onClick={search}
                />
            </div>
            {booksList &&
                <>
                        <div className={styles.container}>
                            <ul className={styles.responsive_table}>
                                <li className={styles.table_header}>
                                    <div className={styles.col_1}>Id</div>
                                    <div className={styles.col_1}> </div>
                                    <div className={styles.col_2}>Title</div>
                                    <div className={styles.col_3}>Authors</div>
                                    <div className={styles.col_4}>Public</div>
                                    <div className={styles.col_5}>Trial</div>
                                </li>
                                {booksList.map(el =>
                                <li className={styles.table_row}
                                    key={el.id}
                                >
                                    <div className={styles.col_1}>{el.id}</div>
                                    <div className={styles.col_1}><img src={el.coverUrl} style={{width: "50px"}}/></div>
                                    <div className={styles.col_2}
                                         onClick={() => openBook(el.id)}
                                    >
                                        {el.title}
                                    </div>
                                    <div className={styles.col_3}>
                                        {el.authors.map(authors => <div key={authors.id}>{`${authors.firstName} ${authors.lastName}`}</div>)}
                                    </div>
                                    <div className={styles.col_4}>
                                        <div className={styles.publish}
                                             onClick={() => publishBook(el.id)}
                                        >
                                            {el.public ?
                                                <MdOutlineCheckBox/>
                                                :
                                                <MdOutlineCheckBoxOutlineBlank/>
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.col_5}>{`${el.trial}`}</div>
                                    <IoTrashOutline className={styles.trash}
                                                    onClick={() => deleteBook(el.id)}
                                    />
                                </li>
                                    )}
                            </ul>
                        </div>
                </>
            }
            {isFetching && <div className={styles.fetch}>
                <RiLoaderFill className={styles.loader}/>
            </div>}
            {hasMoreBooks &&
                <div className={styles.loadBtn}
                     onClick={loadMore}
                >
                    загрузить еще
                </div>
            }
        </div>
    )
}
