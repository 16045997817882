
const pdfUrl = "https://book-feed-image-storage.s3.eu-central-1.amazonaws.com/prod/public/user_agreement.pdf"

const PdfViewer = () => {
    return (
        <div>
            <iframe
                src={pdfUrl}
                width="100%"
                height="800px"
                style={{ border: "none" }}
                title="PDF Viewer"
            />
        </div>
    );
};

export default PdfViewer;
