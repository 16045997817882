import React, {FC} from "react";
import styles from "./styles/admin.module.css";
import {IoCloseOutline} from "react-icons/io5";


type ModalNotePropsType = {
    text: string,
    closeModal: () => void
}
export const ModalNote:FC<ModalNotePropsType> = (props) => {
    return (
        <div className={styles.modalContainer}>
            <div><IoCloseOutline onClick={() => props.closeModal()}/></div>
            <div>{props.text}</div>
        </div>
    )
}
