import React from "react";
import {RiLoaderFill} from "react-icons/ri";
import styles from './styles/app.module.css'

export const PreloaderComponent = () => {
    return (
        <div className={styles.fetchComponent}>
            <RiLoaderFill className={styles.loader}/>
        </div>
    )
}
