import React, {FC} from "react";
import styles from "./styles/admin.module.css";
import {IoCloseOutline} from "react-icons/io5";
import {deleteChapterAsync} from "../../app/reducers/books";
import {useAppDispatch} from "../../app/hooks";

type ModalDeleteChapterPropsType = {
    text: string,
    closeModal: () => void,
    id: number
}

export const ModalDeleteChapter:FC<ModalDeleteChapterPropsType> = (props) => {
    const dispatch = useAppDispatch()
    const deleteChapter = () => {
        dispatch(deleteChapterAsync(props.id))
        props.closeModal()
    }
    return (
        <div className={styles.modalContainer}>
            <div><IoCloseOutline onClick={() => props.closeModal()}/></div>
            <div>{props.text}</div>
            <div className={styles.deleteBtn}
                 onClick={deleteChapter}
            >
                Удалить
            </div>
        </div>
    )
}
