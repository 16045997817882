import React from "react";
import styles from "../styles/app.module.css";

export const SystemSettingComponent = () => {
    return (
        <div className={styles.block}>
            <div className={styles.settingBlock}>
                Количество постов в день:
                <div style={{width: "90%", margin: "5px"}}>
                    3
                </div>
            </div>
            <div className={styles.settingBlock}>
                Тайм зона:
                <div style={{width: "90%", margin: "5px"}}>
                    MSK
                </div>
            </div>
        </div>
    )
}
