import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {BookFeedClient} from "../../api/BookFeedClient";
import {IBook, IBookContent, IBooks, IChapter} from "../../types";

interface appState {
    booksList: Array<IBook>
    currentBook: IBook | null
    isFetching: boolean
    currentPageBooks: number,
    hasMoreBooks: boolean,
    searchBook: string,
    bookContent: Array<IBookContent>,
    currentChapter: IChapter | null
}

const initialState: appState = {
    booksList: [],
    currentBook: null,
    isFetching: false,
    currentPageBooks: 0,
    hasMoreBooks: false,
    searchBook: "",
    bookContent: [],
    currentChapter: null
}


export const booksSlice = createSlice(
    {
        name: 'books',
        initialState,
        reducers: {
            setBooks: (state, action:PayloadAction<IBooks>) => {
                state.booksList = [...state.booksList, ...action.payload.elements]
                state.currentPageBooks = state.currentPageBooks + 1
                state.hasMoreBooks = state.currentPageBooks < action.payload.totalPages
            },
            deleteBook: (state, action: PayloadAction<number>) => {
                state.booksList = state.booksList.filter(el => el.id !== action.payload)
            },
            setSearchBooks: (state, action:PayloadAction<IBooks>) => {
                state.booksList = action.payload.elements
                state.currentPageBooks = 1
                state.hasMoreBooks = state.currentPageBooks < action.payload.totalPages
            },
            setCurrentSearchBook: (state, action:PayloadAction<string>) => {
                state.currentPageBooks = 0
                state.searchBook = action.payload
            },
            setIsFetching: (state, action: PayloadAction<boolean>) => {
                state.isFetching = action.payload
            },
            resetBooks: (state) => {
                state.booksList = []
                state.currentPageBooks = 0
                state.hasMoreBooks = false
            },
            setBookContent: (state, action:PayloadAction<Array<IBookContent>>) => {
                state.bookContent = action.payload
            },
            setCurrentChapter: (state, action: PayloadAction<IChapter>) => {
                state.currentChapter = action.payload
            },
            publishBook: (state, action:PayloadAction<number>) => {
                state.booksList = state.booksList.map(el => {
                    if(el.id === action.payload){
                        el.public = !el.public
                    }
                    return el
                })
            },
            deleteChapter: (state, action:PayloadAction<number>) => {
                state.bookContent = state.bookContent.filter(el => el.id !== action.payload)
            },
            setCurrentBook: (state, action:PayloadAction<IBook>) => {
                state.currentBook = action.payload
            },
            updateAnnotation: (state, action: PayloadAction<IBook>) => {
                state.currentBook!.annotation = action.payload.annotation
            },
            updateCover: (state, action: PayloadAction<IBook>) => {
                state.currentBook!.coverUrl = action.payload.coverUrl
            }
        }
    }
)


export const {
    setBooks,
    deleteBook,
    setSearchBooks,
    setCurrentSearchBook,
    setIsFetching,
    resetBooks,
    setBookContent,
    setCurrentChapter,
    publishBook,
    deleteChapter,
    setCurrentBook,
    updateAnnotation,
    updateCover
} = booksSlice.actions

export const getBooksListAsync = (search: string): AppThunk => async  (dispatch: any, getState) => {
    const bookReducer = getState().booksReducer
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    if(bookReducer.searchBook !== search){
        let result = await BookFeedClient.getBooksList(search, token!, 0)
        dispatch(setCurrentSearchBook(search))
        dispatch(setSearchBooks(result))
    }else{
        let result = await BookFeedClient.getBooksList(search, token!, bookReducer.currentPageBooks)
        dispatch(setBooks(result))
    }
}

export const deleteBookAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    try {
        dispatch(setIsFetching(true))
        await BookFeedClient.deleteBook(id, token!)
        dispatch(deleteBook(id))
        dispatch(setIsFetching(false))
    } catch (error) {

    }
}

export const getBookContentAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    dispatch(setIsFetching(true))
    let result = await BookFeedClient.getBookContent(id, token!)
    dispatch(setBookContent(result))
    dispatch(setIsFetching(false))
}

export const getBookChapterContentAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    dispatch(setIsFetching(true))
    let result = await BookFeedClient.getBookChapterContent(id, token!)
    dispatch(setCurrentChapter(result))
    dispatch(setIsFetching(false))
}
export const publishBookAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    await BookFeedClient.publishBook(id, token!)
    dispatch(publishBook(id))
}
export const deleteChapterAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    await BookFeedClient.deleteChapter(id, token!)
    dispatch(deleteChapter(id))
}
export const getBookInfoAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    const result = await BookFeedClient.getBook(id, token!)
    dispatch(setCurrentBook(result))
}

export const updateAnnotationAsync = (id: number, annotation: string): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    const result = await BookFeedClient.updateAnnotationBook(id, token!, annotation)
    dispatch(updateAnnotation(result))
}

export const updateCoverAsync = (id: number, file: FormData): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    const result = await BookFeedClient.updateCoverBook(id, token!, file)
    dispatch(updateCover(result))
}
export default booksSlice.reducer;
