import {FC, useEffect, useRef} from 'react';
import {FaPause, FaPlay} from "react-icons/fa6";
import styles from './styles/home.module.css'


type AudioPlayerPropsType = {
    url: string,
    id: number,
    time: string,
    isPlaying: boolean;
    currentPlayingId: number | null;
    onPlayToggle: (id: number) => void;
}

export const AudioPlayer:FC<AudioPlayerPropsType> = ({ url, id, time,  isPlaying, currentPlayingId, onPlayToggle }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            onPlayToggle(id);
        }
    };
    useEffect(() => {
        if (currentPlayingId !== id && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Сброс в начало при переключении на другой плеер
        }
    }, [currentPlayingId, id]);

    return (
        <div className={styles.audioContainer}>
            <div className={styles.audioBtn}>
                <div onClick={toggleAudio}>
                    {isPlaying ?
                        <FaPause className={styles.audioBtnIcon}/>
                        :
                        <FaPlay className={styles.audioBtnIcon}/>
                    }
                </div>
                <audio ref={audioRef} src={url}> </audio>
            </div>
            <div className={styles.audioInfo}>
                <div>#{id}.mp3</div>
                <div>{time}</div>
            </div>
        </div>
    );
};
