import React, {FC, useEffect, useRef, useState} from "react";
import styles from "../styles/app.module.css";
import {IoSettingsOutline} from "react-icons/io5";
import {PiBookBookmarkLight} from "react-icons/pi";
import {HiChevronDown} from "react-icons/hi2";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import 'rc-slider/assets/index.css';
import useClickOutside from "../../../utils/clickOutside";
import {moveForwardAsync, setStatusAsync} from "../../../app/reducers/bookFeedSlice";
import {Tooltip} from 'react-tooltip'
import {SettingsContainer} from "./SettingsContainer";
import {TableOfContents} from "../TableOfContents";


type SettingsBarPropsType = {
    setIsScrolledToEnd: (value: boolean) => void
}
export const SettingsBar:FC<SettingsBarPropsType> = (props) => {
    const dispatch = useAppDispatch()
    const {
        user,
    } = useAppSelector((state) => state.userReducer);
    const {
        posts,
        status,
        bookContent
    } = useAppSelector((state) => state.bookFeedReducer);


    useEffect(() => {
        dispatch(setStatusAsync())
    }, [posts])

    const [isOpenSettings, setIsOpenSettings] = useState(false)
    const [isOpenBookContent, setIsOpenBookContent] = useState(false)


    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, isOpenSettings, () => setIsOpenSettings(false))

    const skipTelegramPosts = () => {
        dispatch(moveForwardAsync())
        props.setIsScrolledToEnd(true)
    }

    return (
        <div className={styles.settingsContainer} ref={ref}>
            <PiBookBookmarkLight className={styles.buttonSet} onClick={() => setIsOpenBookContent(!isOpenBookContent)}/>
            {status &&
                <div style={{fontSize: "0.8em"}}>
                    {status.progress.toFixed(2)}%
                </div>
            }
            {status &&
                <div className={styles.postsAheadContainer}>
                <div className={styles.postsAhead}
                     data-tooltip-id="my-tooltip"
                     data-tooltip-content="Посты в телеграме"
                     style={status.postsAhead === 0 ? {background: `var(--background-containers)`, color: `var(--font-color)`, opacity: "0.7"} : {}}
                >
                    {status.postsAhead}
                </div>
                    <HiChevronDown className={styles.arrow}
                                   onClick={skipTelegramPosts}
                    />
                </div>
            }
            <Tooltip id="my-tooltip" />

            <IoSettingsOutline className={styles.buttonSet} onClick={() => setIsOpenSettings(!isOpenSettings)}/>
            {user &&
                <SettingsContainer isOpenSettings={isOpenSettings} settings={user.setting}/>
            }
                <TableOfContents
                    chapters={bookContent}
                    isOpenBookContent={isOpenBookContent}
                    settings={user!.setting}
                    setIsOpenBookContent={setIsOpenBookContent}
                />
        </div>
    )
}
