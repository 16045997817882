
export const applyStyle = (text: string, style?: string) => {
    if (style === 'NEW_LINE_AFTER') {
        return <>{text}<br/></>
    }else if (style === 'TITLE'){
        return <h2>{text}</h2>
    }else if (style === 'TITLE_1'){
        return <h3>{text}</h3>
    }else if (style === 'TITLE_2'){
        return <h4>{text}</h4>
    }else if (style === 'TITLE_3'){
        return <h4>{text}</h4>
    }
    return <>{text}</>
}
