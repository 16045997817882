import {RefObject} from "react";

export const scrollToElement = (targetRef: RefObject<HTMLDivElement>) => {
    if (targetRef.current) {
        const elementPosition = targetRef.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 50;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};