import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loginAdminAsync} from "../../app/reducers/userSlice";
import {useNavigate} from "react-router-dom";

export const Admin = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        adminToken,
        errorMessage
    } = useAppSelector((state) => state.userReducer);

    useEffect(() => {
        dispatch(loginAdminAsync())
        if(adminToken){
            navigate('/admin/books')
        }
    }, [adminToken])

    return (
        <>
            {errorMessage && <div>{errorMessage}</div>}
        </>

    )
}
