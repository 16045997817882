import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {userInProgressAsync} from "../../app/reducers/statistic";
import styles from "./styles/admin.module.css";
import {Navbar} from "./Navbar";
import {loginAdminAsync} from "../../app/reducers/userSlice";

export const Statistic = () => {
    const {
        statistic,
        hasMoreStatistic
    } = useAppSelector((state) => state.statisticReducer);
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loginAdminAsync())
        dispatch(userInProgressAsync())
    }, [])

    const  loadMoreStatistic = () => {
        dispatch(userInProgressAsync())
    }
    return (
        <div>
            <Navbar/>
            {statistic &&
                <>
                    <div className={styles.container}>
                        <ul className={styles.responsive_table}>
                            <li className={styles.table_header}>
                                <div className={styles.col_1}>user Id</div>
                                <div className={styles.col_2}>book Title</div>
                                <div className={styles.col_4}>Username</div>
                                <div className={styles.col_4}>Name</div>
                                <div className={styles.col_4}>Progress</div>
                            </li>
                            {statistic.map(el =>
                                <li className={styles.table_row}
                                    key={el.userId}
                                >
                                    <div className={styles.col_1}>{el.userId}</div>
                                    <div className={styles.col_2}>{el.bookTitle}</div>
                                    <div className={styles.col_4}>{el.username}</div>
                                    <div className={styles.col_4}>{`${el.firstName} ${el.lastName}`}</div>
                                    <div className={styles.col_4}>{`${el.progress.toFixed(2)}%`}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                    {hasMoreStatistic &&
                        <div className={styles.loadBtn}
                             onClick={loadMoreStatistic}
                        >
                            загрузить еще
                        </div>
                    }
                </>
            }
        </div>
    )
}
