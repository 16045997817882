import {RefObject, useEffect} from "react";

export default function useClickOutside(ref: RefObject<HTMLDivElement>, isOpenSettings: boolean, callback: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                const dropDown = document.getElementById("dropDown")
                if (dropDown && !dropDown.contains(event.target)) {
                    callback();
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpenSettings]);
}