import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {IBookContent, IChapter, IPost, IStatus} from "../../types";
import {BookFeedClient} from "../../api/BookFeedClient";

interface appState {
    currentPost: IPost | null
    posts: Array<IPost>
    status: IStatus | null,
    bookContent: Array<IBookContent>
    chapterContent: IChapter | null,
    note: string | null,
    isFetching: boolean
}

const initialState: appState = {
    currentPost: null,
    posts: [],
    status: null,
    bookContent: [],
    chapterContent: null,
    note: null,
    isFetching: false
}

export const bookFeedSlice = createSlice (
    {
        name: 'bookFeed',
        initialState,
        reducers: {

            addPost: (state, action: PayloadAction<IPost>) => {
                state.posts = [...state.posts, action.payload]
            },
            addCurrentPost:(state, action: PayloadAction<Array<IPost>>) => {
                state.posts = action.payload
            },
            addTelegramPosts: (state, action: PayloadAction<Array<IPost>>) => {
                state.posts = [...state.posts, ...action.payload]
            },
            setStatus: (state, action: PayloadAction<IStatus>) => {
                state.status = action.payload
            },
            setBookContent: (state, action: PayloadAction<Array<IBookContent>>) => {
                state.bookContent = action.payload
            },
            setChapterContent: (state, action:PayloadAction<IChapter>) => {
                state.chapterContent = action.payload
            },
            deleteChapterContent:(state) => {
                state.chapterContent = null
            },
            setNote: (state, action:PayloadAction<string>) => {
                state.note = action.payload
            },
            setIsFetching: (state, action: PayloadAction<boolean>) => {
                state.isFetching = action.payload
            }
        }
    }
)


export const {
    addPost,
    addCurrentPost,
    setStatus,
    setBookContent,
    setChapterContent,
    deleteChapterContent,
    addTelegramPosts,
    setNote,
    setIsFetching
} = bookFeedSlice.actions


export const nextPostAsync = (): AppThunk => async (dispatch: any, getState) => {
    let userReducer = getState().userReducer
    let token = userReducer.token
    if(token) {
        let result = await BookFeedClient.nextPost(token)
        dispatch(addPost(result))
    }
}

export const getCurrentPostAsync = (token: string): AppThunk => async  (dispatch: any) => {
    let result = await BookFeedClient.readingPost(token)
        dispatch(addCurrentPost(result))
}

export const setStatusAsync = (): AppThunk => async (dispatch: any, getState) => {
    let userReducer = getState().userReducer
    let token = userReducer.token
    let result = await BookFeedClient.readStatus(token!)
    dispatch(setStatus(result))
}
export const bookContentAsync = (): AppThunk => async (dispatch: any, getState) => {
    let userReducer = getState().userReducer
    let token = userReducer.token
    let result = await BookFeedClient.bookContents(token!)
    dispatch(setBookContent(result))
}

export const chapterContentAsync = (id: number): AppThunk => async (dispatch: any, getState) => {
    dispatch(setIsFetching(true))
    let userReducer = getState().userReducer
    let token = userReducer.token
    let result = await BookFeedClient.chapterContent(token!, id)
    dispatch(setChapterContent(result))
    dispatch(setIsFetching(false))
}

export const moveForwardAsync = (): AppThunk => async (dispatch: any, getState) => {
    let userReducer = getState().userReducer
    let token = userReducer.token
    let result = await BookFeedClient.moveForward(token!)
    dispatch(addTelegramPosts(result))
}

export const noteAsync = (bookId: number, identifier: string): AppThunk => async  (dispatch: any) => {
        let result = await BookFeedClient.note(bookId, identifier)
        dispatch(setNote(result))
}

export default bookFeedSlice.reducer;
