import React, {FC} from "react";
import styles from "../styles/app.module.css";
import {FaTelegramPlane} from "react-icons/fa";
import {ISettings} from "../../../types";
import {useAppDispatch} from "../../../app/hooks";
import {updateSettingsAsync} from "../../../app/reducers/userSlice";

type ThemeSettingComponentPropsType = {
    userSetting: ISettings,
}

export const ThemeSettingComponent:FC<ThemeSettingComponentPropsType> = (props) => {
    const dispatch = useAppDispatch()
    const themes = ["WHITE", "BEIGE", "GREY", "BLACK", "TELEGRAM"]

    const chooseColorTheme = (theme: string) => {
        const set = {
            theme,
            fontSize: props.userSetting.fontSize,
            lineHeight: props.userSetting.lineHeight,
            fontStyle: props.userSetting.fontStyle
        }
        dispatch(updateSettingsAsync(set))
    }

    const getStyle = (theme: string) => {
        let completeStyle = styles.colorSet
        if (theme == "WHITE") {
            completeStyle += " " + styles.colorSetWhite;
        } else if (theme == "BEIGE") {
            completeStyle += " " + styles.colorSetBeige;
        } else if (theme == "GREY") {
            completeStyle += " " + styles.colorSetGrey;
        } else if (theme == "BLACK") {
            completeStyle += " " + styles.colorSetBlack;
        } else {
            completeStyle +=  " " + styles.colorSetTelegram;
        }
        if (props.userSetting.theme === theme) {
            completeStyle += " " + styles.colorSetActive;
        }
        return completeStyle
    }

    return (
        <div className={styles.block}>
            <div className={styles.settingBlock}
                 style={{height: "80%"}}
            >
                Цветовая тема:
                <div className={styles.colors}>
                    {themes.map(el =>
                        <div className={getStyle(el)}
                             key={el}
                             onClick={() => chooseColorTheme(el)}
                        >
                            {el === "TELEGRAM" ? <FaTelegramPlane/>  : <>A</>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
