import React, {FC, useEffect, useRef} from "react";
import {BackButton} from '@vkruglikov/react-telegram-web-app';
import {IBookContent, ISettings} from "../../types";
import styles from "./styles/app.module.css";
import {PiRecordLight} from "react-icons/pi";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {bookContentAsync, chapterContentAsync, deleteChapterContent} from "../../app/reducers/bookFeedSlice";
import {Chapter} from "../admin/Chapter";
import {PreloaderComponent} from "./PreloaderComponent";

type BookUserContentPropsType = {
    chapters: Array<IBookContent>,
    isOpenBookContent: boolean,
    settings: ISettings,
    setIsOpenBookContent: (value: boolean) => void
}

export const TableOfContents:FC<BookUserContentPropsType> = (props) => {
    const {
        chapterContent,
        isFetching
    } = useAppSelector((state) => state.bookFeedReducer);
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(props.isOpenBookContent){
            dispatch(bookContentAsync())
        }
    }, [props.isOpenBookContent])

    const containerStyles = {
        fontSize: 18,
        bottom: props.isOpenBookContent ? "0px" : "-100%"
    }


    const openChapter = (id: number) => {
        dispatch(chapterContentAsync(id))
    }

    const backToApp = () => {
        dispatch(deleteChapterContent())
    }

    const getSubChapters = (chapters: Array<IBookContent>, level: number) => {
        if (!chapters) return <></>;
        let titleStyle: string;
        switch (level) {
            case 1:
                titleStyle = styles.title_1;
                break;
            case 2:
                titleStyle = styles.title_2;
                break;
            case 3:
                titleStyle = styles.title_3;
                break;
            default:
                titleStyle = styles.title_4;
                break;
        }
        return chapters.map((el, i) =>
            <div key={i}>
                <div className={titleStyle}
                     style={{display: "flex", alignItems: "center"}}
                     onClick={() => openChapter(el.id)}
                >
                    {el.title ? el.title : "***"}
                    {el.pinpoint && <PiRecordLight style={{marginLeft: "10px", color: "#E67571"}}/>}
                </div>
                {el.subChapters && getSubChapters(el.subChapters, level + 1)}
            </div>
        );
    }

    const targetRef = useRef<HTMLDivElement>(null);

    const scrollToStartChapter = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToStartChapter()
    }, [chapterContent])

    return (
        <div className={styles.BookContentContainer} style={containerStyles}>
            {isFetching && <PreloaderComponent/>}
            {chapterContent ?
                <>
                    {props.isOpenBookContent && <BackButton onClick={backToApp}/> }
                    <div ref={targetRef}>
                        <Chapter chapter={chapterContent}/>
                    </div>
                </>
                :
                <>
                    {props.isOpenBookContent && <BackButton onClick={() => props.setIsOpenBookContent(false)}/>}
                    {getSubChapters(props.chapters, 1)}
                </>
            }
        </div>
    )
}
