import React, {FC, useRef} from "react";
import styles from "./styles/app.module.css";
import {IoCloseOutline} from "react-icons/io5";
import useClickOutside from "../../utils/clickOutside";

type PopupNotePropsType = {
    text: string,
    close: () => void,
    isShowPopup: boolean
}

export const PopupNote:FC<PopupNotePropsType> = (props) => {

    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, props.isShowPopup, props.close)

    return (
        <div className={styles.popupContainer} ref={ref}>
            <div onClick={() => props.close()}><IoCloseOutline/></div>
            <div>{props.text}</div>
            <div className={styles.closeBtn}
                 onClick={() => props.close()}
            >
                Закрыть
            </div>
        </div>
    )
}
