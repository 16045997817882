import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {BookFeedClient} from "../../api/BookFeedClient";
import {IFile, IFiles} from "../../types";

interface appState {
    filesList: Array<IFile>
    isFetching: boolean
    isProcess: number | null
    currentPageFiles: number,
    hasMoreFiles: boolean,
    searchFile: string,
}

const initialState: appState = {
    filesList: [],
    isFetching: false,
    isProcess: null,
    currentPageFiles: 0,
    hasMoreFiles: false,
    searchFile: ""
}


export const filesSlice = createSlice(
    {
        name: 'files',
        initialState,
        reducers: {
            setFiles: (state, action:PayloadAction<IFiles>) => {
                state.filesList = [...state.filesList, ...action.payload.elements]
                state.currentPageFiles = state.currentPageFiles + 1
                state.hasMoreFiles = state.currentPageFiles < action.payload.totalPages
            },
            setSearchFile:(state, action: PayloadAction<IFiles>) => {
                state.filesList = action.payload.elements
                state.currentPageFiles = state.currentPageFiles + 1
                state.hasMoreFiles = state.currentPageFiles < action.payload.totalPages
            },
            setCurrentSearchFile: (sate, action:PayloadAction<string>) => {
                sate.currentPageFiles = 0
                sate.searchFile = action.payload
            },
            updateFile:(state, action:PayloadAction<IFile>) => {
                state.filesList = state.filesList.map(el => {
                    if(el.id === action.payload.id){
                        el = action.payload
                    }
                    return el
                })
            },
            addFile: (state, action: PayloadAction<IFile>) => {
                state.filesList = [...state.filesList, action.payload]
            },
            setIsFetching: (state, action: PayloadAction<boolean>) => {
                state.isFetching = action.payload
            },
            setIsProcess: (state, action: PayloadAction<number | null>) => {
                state.isProcess = action.payload
            },
            deleteFile: (state, action:PayloadAction<number>) => {
                state.filesList = state.filesList.filter(el => el.id !== action.payload)
            },
            resetFiles: (state) => {
                state.filesList = []
                state.currentPageFiles = 0
                state.hasMoreFiles = false
            }
        }
    }
)


export const {
    setFiles,
    setSearchFile,
    setCurrentSearchFile,
    updateFile,
    setIsFetching,
    addFile,
    setIsProcess,
    deleteFile,
    resetFiles
} = filesSlice.actions



export const getFilesListAsync = (search: string,): AppThunk => async  (dispatch: any, getState) => {
    const adminReducer = getState().filesReducer
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    if(adminReducer.searchFile !== search){
        let result = await BookFeedClient.getFilesList(search, token!, 0)
        dispatch(setCurrentSearchFile(search))
        dispatch(setSearchFile(result))
    }else{
        let result = await BookFeedClient.getFilesList(search, token!, adminReducer.currentPageFiles)
        dispatch(setFiles(result))
    }
}

export const addFileAsync = (file: FormData): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    try {
        dispatch(setIsFetching(true))
        let result = await BookFeedClient.addFile(file, token!)
        if(!result.alreadyUploaded){
            dispatch(addFile(result))
        }
        dispatch(setIsFetching(false))
    } catch (error) {
        dispatch(setIsFetching(false))
        // @ts-ignore
        const message = error.data.data.message
    }
}

export const processFileAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    try {
        dispatch(setIsProcess(id))
        let result = await BookFeedClient.processFile(id, token!)
        dispatch(updateFile(result))
        dispatch(setIsProcess(null))
    } catch (error) {

    }
}
export const deleteFileAsync = (id: number): AppThunk => async  (dispatch: any, getState) => {
    const authReducer = getState().userReducer
    const token = authReducer.adminToken
    try {
        dispatch(setIsFetching(true))
        await BookFeedClient.deleteFile(id, token!)
        dispatch(deleteFile(id))
        dispatch(setIsFetching(false))
    } catch (error) {

    }
}
export default filesSlice.reducer;
