import React from 'react';
import {Login} from "./components/user/Login";
import {Route, Routes} from "react-router-dom"
import {Admin} from "./components/admin/Admin";
import {CurrentBook} from "./components/admin/CurrentBook";
import {BookContent} from "./components/admin/BookContent";
import {AdminLogin} from "./components/admin/AdminLogin";
import {Note} from "./components/user/Note";
import {Books} from "./components/admin/Books";
import {Files} from "./components/admin/Files";
import {Statistic} from "./components/admin/Statistic";
import {HomePage} from "./components/home-page/HomePage";
import PdfViewer from "./components/home-page/PdfViewer";

function App() {

  return (
      <>
          <Routes>
              <Route path={'/login'} element={<Login/>}/>
              <Route path={'/'} element={<HomePage/>}/>
              <Route path={'/user-agreement'} element={<PdfViewer/>}/>
              <Route path={'/admin/:hash'} element={<AdminLogin/>}/>
              <Route path={'/admin'} element={<Admin/>}/>
              <Route path={'/admin/books'} element={<Books/>}/>
              <Route path={'/admin/files'} element={<Files/>}/>
              <Route path={'/admin/statistic'} element={<Statistic/>}/>
              <Route path={'/admin/books/book/:id'} element={<CurrentBook/>}/>
              <Route path={'/admin/books/book/:id/chapter/:id'} element={<BookContent/>}/>
              <Route path={'/book/:id/note/:id'} element={<Note/>}/>
          </Routes>
      </>
  );
}

export default App;
