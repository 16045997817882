import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {getUserAsync, loginAsync} from "../../app/reducers/userSlice";
import {getCurrentPostAsync} from "../../app/reducers/bookFeedSlice";
import {UserComponent} from "./UserComponent";
import {PreloaderComponent} from "./PreloaderComponent";


export const Login = () => {
    const width = window.innerWidth
    const dispatch = useAppDispatch()
    const {
        user,
        token,
        isFetching
    } = useAppSelector((state) => state.userReducer);

    const [initDataUnsafe, initData] = useInitData();

    useEffect(() => {
        if(initData) {
            dispatch(loginAsync(initData))
        }
    }, [initData])

    useEffect(() => {
        if (token) {
            dispatch(getUserAsync(token))
            dispatch(getCurrentPostAsync(token))
        }
    }, [token])

    return (
        <div style={{width: width}}>
            {/*{token}*/}
            {!user && <PreloaderComponent/>}
            {user &&
                <UserComponent userSettings={user.setting}/>
            }
        </div>
    )
}
