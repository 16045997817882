import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import styles from "./styles/admin.module.css"
import {GoDownload} from "react-icons/go";
import {addFileAsync, deleteFileAsync, getFilesListAsync, processFileAsync, resetFiles} from "../../app/reducers/files";
import {FaBook} from "react-icons/fa";
import {IoTrashOutline} from "react-icons/io5";
import {RiLoaderFill} from "react-icons/ri";
import {CiSearch} from "react-icons/ci";
import {useNavigate} from "react-router-dom";
import {Navbar} from "./Navbar";
import {loginAdminAsync} from "../../app/reducers/userSlice";


export const Files = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        filesList,
        isFetching,
        isProcess,
        hasMoreFiles
    } = useAppSelector((state) => state.filesReducer);


    useEffect(() => {
        dispatch(loginAdminAsync())
        dispatch(getFilesListAsync(""))
        return () => {
            dispatch(resetFiles())
        }
    }, [])

    const loadImage = (e:React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData()
        if(e.target.files){
            formData.append("file", e.target.files[0])
            dispatch(addFileAsync(formData))
        }
    }

    const processFile = (id: number) => {
        dispatch(processFileAsync(id))
    }
    const deleteFile = (id: number) => {
        dispatch(deleteFileAsync(id))
    }

    const [searchFile, setSearchFile] = useState("")

    const changeSearchFile = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === ""){
            dispatch(getFilesListAsync(""))
        }
        setSearchFile(e.target.value)
    }

    const search = () => {
        dispatch(getFilesListAsync(searchFile))
    }
    const pressHandler = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            search()
        }
    };
    const loadMore = () => {
        dispatch(getFilesListAsync(""))
    }
    const openBook = (id: number) => {
        navigate(`book/${id}`)
    }

     return (
        <div style={{position: "relative"}}>
            <Navbar/>
            <div className={styles.addFileContainer}>
                <GoDownload />
                <input type={"file"}
                       className={styles.addFileInput}
                       placeholder={"Загрузить файл"}
                       onChange={loadImage}
                />
            </div>
            <div className={styles.search}>
                <input placeholder={"поиск"}
                       value={searchFile}
                       onChange={changeSearchFile}
                       onKeyPress={pressHandler}
                />
                <CiSearch onClick={search}/>
            </div>
            {filesList &&
                <>
                    <div className={styles.container}>
                        <ul className={styles.responsive_table}>
                            <li className={styles.table_header}>
                                <div className={styles.col_1}>Id</div>
                                <div className={styles.col_2}>File Name</div>
                                <div className={styles.col_3}>book Title</div>
                                <div className={styles.col_4}>Size</div>
                                <div className={styles.col_5}>source</div>
                                <div className={styles.col_1}>Book id</div>
                            </li>
                            {filesList.map(el =>
                                <li className={styles.table_row}
                                    key={el.id}
                                >
                                    <div className={styles.col_1}>{el.id}</div>
                                    <div className={styles.col_2}>{el.fileName}</div>
                                    <div className={styles.col_3}>{`${el.bookTitle}`}</div>
                                    <div className={styles.col_4}>{el.size}</div>
                                    <div className={styles.col_5}>{el.source}</div>
                                    <div className={styles.col_1}>
                                        {!el.bookTitle
                                            ?
                                            <>
                                                {isProcess && isProcess === el.id ?
                                                    <RiLoaderFill className={styles.loader}/>
                                                    :
                                                    <FaBook className={styles.bookBtn}
                                                            onClick={() => processFile(el.id)}/>
                                                }
                                            </>
                                            :
                                            <div className={styles.bookId}
                                                onClick={() => openBook(el.bookId)}
                                            >
                                                {el.bookId}
                                            </div>
                                        }

                                    </div>
                                    <IoTrashOutline className={styles.trash}
                                                    onClick={() => deleteFile(el.id)}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            }
            {isFetching &&
                <div className={styles.fetch}>
                    <RiLoaderFill className={styles.loader}/>
                </div>
            }
            {hasMoreFiles &&
                <div className={styles.loadBtn}
                     onClick={loadMore}
                >
                    загрузить еще
                </div>
            }
        </div>
    )
}
